@import "index.scss";

.user-header {
    margin-top: 30px;
    align-items: center;
    text-align: center;
}

.user-name {
    font-size: 48px;
    font-weight: 600;
}

