@import "index.scss";

.album-header {
    margin-top: 30px;
    align-items: center;
    text-align: center;
}

.album-title {
    font-size: 48px;
    font-weight: 600;
}

.album-artist {
    color: #494949;
}

.album-content {
    border-radius: 25px;
    background-color: #EDEDED;
    padding: 45px 60px 20px 60px;
}

.album-comment {
    margin-bottom: 30px;
    padding: 25px 35px 25px 35px;
    border-radius: 25px;
    background-color: #C4C4C4;
}

.album-comment-user {
    color: #3A3A3A;
    font-weight: 600;
    font-size: 18px;
}

.album-comment-content {
    font-weight: 600;
}

.album-comment-star {
    margin-right: 5px;
}