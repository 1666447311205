
$secondary-text:#858585;
$item-color: #EDEDED;
$border-radius: 15px;

.explore-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    width: 100%;
    /* height: 225px; */

    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    background: $item-color;
    border-radius: $border-radius;
}

.explore-user {
    position: relative;
    //left: 38px;
    margin-top: 20px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: 20px;
    width: 160px;
}

.explore-album {
    position: relative;
    //left: 38px;
    margin-top: 20px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: 20px;
}

.explore-user-name {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    color: black;
}

.explore-user-followers {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;

    color: #7B7B7B;
}

.explore-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 39px;
}