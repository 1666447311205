@import "index.scss";

.intro {
    font-weight: bold;
    font-size: 5vw;
}

.intro-container {
    margin-top: 75px;
    text-align: center;
    margin-bottom: 75px;
}

.signup {
    margin-top: 25px;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 25px 10px 25px;
}