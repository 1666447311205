@import url('https://fonts.googleapis.com/css2?family=Padauk&display=swap');

$secondary-text:#858585;
$item-color: #EDEDED;
$border-radius: 15px;

.navbar-brand {
    font-family: 'Padauk', sans-serif !important; 
    letter-spacing: 0.3em;
    font-size: 24px;
    font-weight: 800;
}

.login-container {
    // background-color: $item-color;
    // position: absolute;
    // border-radius: $border-radius;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin-right: -50%;                
    // transform: translate(-50%, -50%);
}

.footer {
    width: 100%;
}

.copyright {
    color: grey;
}

.search-box {
    
}

body {
    font-family: 'Inter', sans-serif;
}