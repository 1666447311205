@import "index.scss";

.feed {
    margin-top: 30px;
}

.feed-profile-img {
    width: 18px;
    margin-bottom: 2.5px;
    margin-right: 5px;
}

.feed-content {
    position: relative;
    left: 38px;
    margin-bottom: 85px;
}

.feed-item {
    display: flex;
    flex-wrap: wrap;
    
    width: 100%;
    /* height: 225px; */

    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    background: $item-color;
    border-radius: $border-radius;
}

.feed-title {
    position: relative;
    height: 53px;
    top: 45px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    /* or 278% */

    display: flex;
    align-items: center;
    margin-top: 0px;
    line-height: 30px;
}

.feed-artist {
    position: relative;
    height: 31px;
    
    top: 40px;
    margin-left: 0;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    /* or 556% */

    display: flex;
    align-items: center;

    color: $secondary-text;
}

.feed-image {
    float: left;
    position: relative;
    width: 150px;
    height: 150px;
    left: 38px;
    top: 36px;
    padding: 0;
    margin-right: 32px;
}

.feed-stars {
    position: relative;
    top: 50px;
}

.feed-star {
    margin-right: 7px;
}

.feed-user {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    position: relative;
    top: 40px;
}