@import "index.scss";

.artist-header {
    margin-top: 30px;
    align-items: center;
    text-align: center;
}

.artist-name {
    font-size: 48px;
    font-weight: 600;
}

.artist-listeners {
    color: #494949;
}

.artist-content {
    border-radius: 25px;
    background-color: #EDEDED;
    padding: 45px 50px 0px 50px;
    margin: auto;
}

.artist-albums {
    margin: auto;
}

.artist-album {
    margin-right: 2%;
    
}